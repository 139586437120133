import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#F29FC5';
const footerRelatedLinks = [
	{
		title: 'Ecole Technique de la Vallée de Joux',
		url: '/en/projects/ecole-technique-de-la-vallee-de-joux/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectAckTypoPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: { eq: "projects/vidy/img-VIDY-cover.jpg" }
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/vidy/img-VIDY-cover-mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/vidy/SUPT_Portfolio-Vidy-image-1.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/vidy/SUPT_Portfolio-Vidy-image-2.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/vidy/SUPT_Portfolio-Vidy-image-3.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: {
						eq: "projects/vidy/SUPT_Portfolio-Vidy-image-4.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: {
						eq: "projects/vidy/SUPT_Portfolio-Vidy-artiste.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/theatre-de-vidy-lausanne/',
					lang: 'fr',
				},
			]}
			title="Théâtre de Vidy-Lausanne - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Théâtre de Vidy-Lausanne"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Telling the story of the theater’s creative spirit
							and the wealth of ideas by way of an efficient and
							simple website.
						</p>

						<ListText
							className="element-spacing"
							title="Our services"
							list={['UX design', 'Design', 'Web development']}
						/>
					</div>
					<div className="project-description text-content">
						<h3>The challenge</h3>
						<p>
							The Théâtre de Vidy's website aims to reflect not
							only the creative and lively spirit of theatrical
							culture, but also its wealth of ideas and
							performances. It must make finding a show
							straightforward but also allow visitors to get
							carried away as they discover the site, requiring it
							to be easy to use while also offering a rich archive
							to dive into. To sum up the challenge at hand, it’s
							this: finding a way to successfully combine
							simplicity and complexity in this site.
						</p>

						<h3>The answer</h3>
						<p>
							Following the information architecture work that the
							Theater commissioned, we collaborated intensively
							with the Vidy team to come up with and create a
							design that communicates the best of both worlds.
						</p>
					</div>
				</div>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Creative abundance, space and layers</h3>
						<p>
							Visually, the design is very rhythmic and lively,
							inspired by the creative abundance of the Theater.
							This creative spirit is expressed through a
							composition that, while being unstructured at times,
							is above all rhythmic and contrasting.
						</p>
						<p>
							The design plays with superimpositions, generating a
							depth that communicates the playfulness of the
							space.
						</p>
					</div>

					<div className="column__media text-content">
						<Video src="1f6a1c2a005664d8ef3c77f326a299ac" alt="" />
					</div>
				</Column>

				<Gallery className="col-full" modifiers="gutter">
					<Image
						{...data.image_1.childImageSharp}
						alt="Website on mobile"
					/>
					<Image
						{...data.image_2.childImageSharp}
						alt="Website on mobile"
					/>
				</Gallery>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="Colors that communicate the contextual layers and offer interactions to the user."
					more="The black sections offer a clear graphic break from the rest of the site. These sections open to tell a story, allowing the visitor to dive into the show’s universe as if they were in a theater."
					cite=""
				/>

				<Video
					className="col-full"
					src="31904e139cc59f53146a531ad43a8b6c"
					alt=""
				/>

				<Video
					className="col-full grid-spacing"
					src="a432adea4c59bbb1670369052258bd54"
					alt=""
				/>

				<Video
					className="col-full"
					src="d4435cf9e2bd2bb3869e50d92b4963da"
					alt=""
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image
						{...data.image_3.childImageSharp}
						alt="Website on mobile"
					/>
					<Image
						{...data.image_4.childImageSharp}
						alt="Website on mobile"
					/>
				</Gallery>

				<Image
					{...data.image_5.childImageSharp}
					alt="Detail page of an artist"
				/>
			</section>
		</Layout>
	);
};

export default ProjectAckTypoPage;
